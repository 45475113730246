import React from "react"
import Layout from "../components/Layout"

import { textPage } from "../styles/textpage.module.css"

export default function PrivacyNotice() {
  return (
    <Layout>
      <article className={`${textPage} container`}>
        <h1>STUDIO KATHAN PRIVACY NOTICE</h1>

        <p>
          Protecting personal data and your privacy is of greatest concern for
          Studio KATHAN.
        </p>
        <p>
          First of all, we are pleased that you are interested in the projects
          of Studio KATHAN This privacy statement informs you about the way in
          which Personal Data are processed by the studio.{" "}
        </p>

        <h2>Terms of use</h2>
        <p>
          This website provides information about Studio KATHAN which has its
          registered office in Belgium at Berthoutstraat 19 in 2060 Antwerp and
          company number 0671.859.810.
        </p>
        <p>
          Studio KATHAN pays much attention and care to its website and strives
          to ensure that all information is as complete, correct,
          comprehensible, accurate and up-to-date as possible. However, despite
          its efforts, Studio KATHAN cannot guarantee that its website or other
          information channels are free from shortcomings or inaccuracies. If
          you should nevertheless find that certain information has shortcomings
          or inaccuracies, you can report these by e-mail to
          hello@studiokathan.com and the studio will do what is necessary to
          rectify this as soon as possible. Studio KATHAN cannot be held liable
          against anyone for any direct or indirect damage resulting from the
          use of (the information on) its website or other information channels.
        </p>
        <p>
          All information on the website or other information channels of Studio
          KATHAN is always of a general nature. The information is not adapted
          to circumstances specific to a particular person, entity or
          nationality. You should not consider this information to be legally
          binding. This information is purely indicative and no rights and
          obligations can be derived from it, unless they are included in a
          valid signed document.
        </p>

        <h2>Privacy statement</h2>
        <p>
          Studio KATHAN attaches great importance to the protection of your
          privacy. Most of the information is available through the website and
          other information channels without you having to provide any personal
          information. In certain cases, for example - your interest in our
          newsletter or placing an order in the webshop - your personal
          information is still requested.
        </p>
        <p>
          We only collect Personal Data that is necessary for the newsletter and
          to send a the placed orders via through our web shop.
   </p>
        <p>
          By providing your Personal Data, you are deemed to have acknowledged
          the use of your Personal Data in accordance with this privacy
          statement.
        </p>
        <p>
          The processing of your Personal Data is subject to this privacy
          statement. For questions and/or comments, please contact
          hello@studiokathan.com
        </p>

        <h3>1. The confidentially of your personal data</h3>
        <p>
          We make every effort to keep the private data we receive from you
          truly private. Your data is stored in a secure database and we do not
          give access to third parties for commercial purposes.
        </p>
        <p>
          More specifically, we can use the Personal Data we collect from you
          for the following purposes:
        </p>
        <ul>
          <li>to identify you as a user of the Website;</li>
          <li>
            to provide you with our services and to execute agreement(s) with
            you;
          </li>
          <li>to send you our newsletter;</li>
          <li>
            to help us evaluate, correct or improve our Website en all related
            products and services;{" "}
          </li>
          <li>
            for internal reasons, such as corporate administration and archiving
            purposes.
          </li>
        </ul>

        <h3>
          2. How long does Studio KATHAN collect your data and how long are they
          stored?
        </h3>
        <p>We collect your Personal Data in the following cases:</p>
        <ul>
          <li>
            when a request for information is sent on the Website (via the
            contact form);
          </li>
          <li>when sending a request to receive our newsletter; </li>
          <li>
            if you call, e-mail or correspond with us in any other way than via
            the Website.
          </li>
        </ul>
        <p>
          We may combine the Personal Data we collect with information that you
          provide to us in another way
        </p>
        <p>
          Personal Data will be kept and processed by us for the duration that
          is required in relation to the purposes.
        </p>
        <p>
          Personal data will be kept and processed by us for the duration of one
          year and will then be evaluated, depending on whether it is still
          relevant with the purposes.
        </p>
        <p>
          All client data will be removed from our systems after seven years.
          Studio KATHAN avoid collecting Personal Data that are not relevant.
        </p>

        <h3>3. Objectives of the processing</h3>
        <p>
          We will use the Personal Data we collect for the following purposes:
        </p>
        <ul>
          <li>to identify you as a user of the Website; </li>
          <li>
            to provide you with information about our projects and services;{" "}
          </li>
          <li>to deliver you our newsletter, if you so choose;</li>
          <li>
            to assist us in evaluating, correcting or improving the Website and
            all related products or services of Studio KATHAN
          </li>
          <li>
            for internal reasons, including business administrative and
            archiving purposes.
          </li>
        </ul>

        <h3>4. Rights of the data subject</h3>
        <p>
          On the basis of both Belgian and European laws on the protection of
          personal data, you have the rights described below. If you wish to
          exercise these rights, send us a written request accompanied by a copy
          of your identity card to hello@studiokathan.com.
        </p>

        <h4>The right of access to Personal Data</h4>
        <p>
          You have the right to instruct us to provide you with all Personal
          Data that we hold about you, provided that the rights of other parties
          involved are not affected.
        </p>
        <h4>The right to rectification of the Personal Data</h4>
        <p>
          We kindly ask you to ensure that the Personal Data in our database are
          as accurate and complete as possible. If you believe that the data
          provided to us are incorrect or incomplete, you are requested to
          inform us via hello@studiokathan.com. We will then correct or
          supplement your Personal Data as soon as possible.
        </p>
        <h4>The right to delete Personal Data</h4>
        <p>
          You have the right to delete your Personal Data without undue delay in
          the following cases:
        </p>
        <ul>
          <li>
            If it is no longer necessary to keep the Personal Data for the
            purposes for which they were collected or otherwise processed;{" "}
          </li>
          <li>
            In the case of withdrawal of permission for permission-based
            processing;{" "}
          </li>
          <li>Processing intended for direct marketing; and</li>
          <li>If the Personal Data were processed unlawfully. </li>
        </ul>
        <p>
          However, there are certain general exclusions to the right to delete.
          These general exclusions include the cases where processing is
          necessary:{" "}
        </p>
        <ul>
          <li>
            to exercise the right to freedom of expression and information;{" "}
          </li>
          <li>to comply with a legal obligation; or </li>
          <li>for instituting, exercising or defending legal claims.</li>
        </ul>
        <h4>The right to restrict the processing of Personal Data</h4>
        <p>
          You have the right to restrict the processing of your Personal Data in
          the following cases:
        </p>
        <ul>
          <li>for disputing the accuracy of your Personal Data; </li>
          <li>
            when the processing is unlawful, but you do not want the Personal
            Data to be deleted; or
          </li>
          <li>
            if you object to the processing of your Personal Data, pending
            verification of that objection.{" "}
          </li>
        </ul>
        <p>
          If processing is restricted on this basis, we may continue to store
          your Personal Data. However, we will only process the data with your
          explicit consent for instituting, exercising or defending legal
          claims, to protect the rights of another natural or legal person, or
          for reasons of essential public interest.
        </p>
        <h4>The right to object</h4>
        <p>
          You have the right to object to our processing of your Personal Data
        </p>

        <h3>5. Cookies</h3>
        <p>
          To improve accessibility and usability, the Studio KATHAN website uses
          Google Analytics, a web analytics service provided by Google Inc.
          ("Google"). Google Analytics uses "cookies" (text files placed on your
          computer) to help the website analyze how users use the site. More
          information about these cookies can be found via Google Analytics use
          cookies . You may refuse the use of cookies by selecting the
          appropriate settings on your browser. However, we point out that in
          that case you may not be able to use all features of this website. By
          using this website, you consent to the processing of data about you by
          Google in the manner and for the purposes set out above.
        </p>
      </article>
    </Layout>
  )
}
